import { backendURL, genericResponseHandler } from ".";
import { Level } from "./levels";
import { levelsData, storesData, regionsData } from "./data";
const populatedstores: Array<PopulatedStore> = storesData.map((store) => {
  const regioninstoreraw = regionsData.find(
    (region) => region._id.$oid === store.region.$oid
  );
  const regioninstoreprocessed = {
    _id: regioninstoreraw?._id.$oid ?? "missingId",
    name: regioninstoreraw?.name ?? "missingName",
    connectedTo:
      regioninstoreraw?.connectedTo.map((connected) => connected.$oid) ?? [],
  };
  const levelinstoreraw = levelsData.find(
    (level) => level._id.$oid === store.level.$oid
  );
  const levelinstoreprocess = {
    _id: levelinstoreraw?._id.$oid ?? "gone its all gone",
    name: levelinstoreraw?.name ?? "heaven/hell you decide",
  };
  return {
    _id: store._id.$oid,
    unit: store.unit,
    level: levelinstoreprocess,
    region: regioninstoreprocessed,
  };
});
interface Store {
  _id: string;
  unit: string;
  level: string;
  name?: string;
  categories?: string;
  region: string;
}
interface PopulatedStore {
  _id: string;
  unit: string;
  level: Level;
  name?: string;
  categories?: string;
  region: {
    _id: string;
    name: string;
    connectedTo: Array<string>;
  };
}
export type { Store, PopulatedStore };

export async function getAllStoresInLevel(
  levelOID: string
): Promise<Array<Store>> {
  return await genericResponseHandler(
    await fetch(`${backendURL}/store/${levelOID}`)
  );
}
export async function getAllStores(): Promise<Array<PopulatedStore>> {
  if (process.env.REACT_APP_ONLY_GET === "enabled") {
    return populatedstores;
  } else {
    return await genericResponseHandler(await fetch(`${backendURL}/store`));
  }
}
export async function addStoreToRegion(
  levelOID: string,
  regionOID: string,
  storeUnit: string
) {
  return await genericResponseHandler(
    await fetch(`${backendURL}/store`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ levelOID, regionOID, storeUnit }),
    })
  );
}
export async function deleteStore(storeOID: string) {
  return await genericResponseHandler(
    await fetch(`${backendURL}/store`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ storeOID }),
    })
  );
}

export async function updateStore(store: PopulatedStore) {
  return await genericResponseHandler(
    await fetch(`${backendURL}/store`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(store),
    })
  );
}
